@charset "UTF-8";
.task-manager-dialog[data-v-bd387048] {
  width: 100%;
}
.task-manager-dialog .task-manager-content[data-v-bd387048] {
    width: 100%;
    height: 700px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.task-manager-dialog .task-manager-content .task-info-wrapper[data-v-bd387048] {
      width: calc(40% - 5px);
      height: 100%;
      text-align: center;
}
.task-manager-dialog .task-manager-content .task-divider-wrapper[data-v-bd387048] {
      width: 10px;
      height: 100%;
      text-align: center;
}
.task-manager-dialog .task-manager-content .task-divider-wrapper .task-divider-content[data-v-bd387048] {
        width: 1px;
        height: 100%;
        background: #ccc;
}
.task-manager-dialog .task-manager-content .task-graph-wrapper[data-v-bd387048] {
      width: calc(40% - 5px);
      height: 100%;
      text-align: center;
      overflow: hidden;
}
.task-manager-dialog .task-manager-content .task-graph-wrapper .task-graph-content[data-v-bd387048] {
        width: 100%;
        height: 100%;
}
.task-manager-dialog .task-manager-content .task-logs-wrapper[data-v-bd387048] {
      width: 20%;
}
.el-divider[data-v-bd387048] {
  height: 100%;
}

/* 利用穿透，设置input边框隐藏 */
.inputDeep > > > .el-input__inner[data-v-bd387048] {
  border: 0;
}
.el-input[data-v-bd387048] {
  width: 280px;
}
